import React, { Fragment } from 'react'
import Layout from '../../layout'
import Accordion from '../../components/BuildingAppsCrashCourse/Accordion'
import ConsoleBanner from '../../components/BuildingAppsCrashCourse/ConsoleBanner'
import LessonSection from '../../components/BuildingAppsCrashCourse/LessonSection'
import LessonResources from '../../components/BuildingAppsCrashCourse/LessonResources'
import LessonHeader from '../../components/BuildingAppsCrashCourse/LessonHeader'
import YoutubeVideo from '../../components/BuildingAppsCrashCourse/YoutubeVideo'
import { transcript } from '../../components/BuildingAppsCrashCourse/transcript'
import { SEO } from '../../components'
import BuildingAppsCrashCourseOgImage from '../../assets/images/building-apps-crash-course-og-image.png'
import './building-apps-crash-course.scss'

const AppReview = () => (
  <Layout className="bacc_layout-styles" isCourseSite>
    <SEO
      title="Excel at the app review"
      description="Ace the app review process and publish your app on the LiveChat Marketplace with our masterclass tips and tricks for success."
      image={BuildingAppsCrashCourseOgImage}
    />
    <div className="bacc_main-wrapper">
      <LessonHeader
        title="4. Excel at the app review"
        summary={
          <Fragment>
            <p>
              In the last episode of the course, you’ll learn how to smoothly go
              through the app review process in the LiveChat Developer Program.
              With this knowledge, you’ll be able to pass the review seamessly
              and publish your app on the Marketplace.
            </p>
            <p>Ready?</p>
          </Fragment>
        }
      />
      <YoutubeVideo id="eEvHYQtCYkw" eventLabel="app review" />
      <section className="bacc_main-section">
        <h3 className="u-font-bold bacc_section-title">Video summary</h3>
        <div>
          {transcript.appReview.map((item, index) => (
            <Accordion
              key={index}
              num={index}
              title={item.title}
              content={item.content}
            />
          ))}
        </div>
      </section>
      <LessonResources
        transcriptSlug="building-apps-crash-course-app-review.pdf"
        articleTitle="App review copy guidelines"
        articleSlug="livechat-app-review-copy-guidelines.pdf"
        pageName="Excel at the app review"
        isAppReview
        isTool
      />
      <LessonSection />
    </div>
    <ConsoleBanner />
  </Layout>
)

export default AppReview
